<template>
  <div>
    <!--:position-option.sync="positionOption"-->
    <referral-add
      :referral-id.sync="referralId"
      :user-data.sync="userData"
      :is-add-new-referral-sidebar-active.sync="isAddNewReferralSidebarActive"
      @refetch-data="refetchData"
      :designation-options="designationOptions"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              class="mr-2"
              v-model="allSelected"
              v-if="referralMetaData.length && $can('referral_delete')"
            >
            </b-form-checkbox>
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50 mr-1"
            />
            <b-button
              v-if="$can('referral_create')"
              variant="primary"
              @click="addReferNow()"
            >
              <span class="text-nowrap">Refer Now</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              class="d-flex align-items-center justify-content-end"
              v-if="selectedItems.length == 0"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Name / Email / Number / Referred By / Skills"
                />
              </b-input-group>
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="statusFilter"
                v-model="statusFilter"
                class="w-50"
                :options="statusOptions"
                placeholder="Select Status.."
                :reduce="(val) => val.code"
                @input="(val) => $emit('update:statusFilter', val)"
              />
            </div>
            <b-button
              v-else-if="$can('referral_delete')"
              class="float-right"
              variant="danger"
              :disabled="selectedItems.length == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ selectedItems.length }} Referral</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedItems"
        name="selectedItems"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchReferralList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap text-center">
              <b-form-checkbox :value="data.item.hashid" class="mr-0">
              </b-form-checkbox>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('referral_show')"
                :to="{
                  name: 'view-referral',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
                v-b-tooltip.hover.v-default
                :title="data.item.name"
              >
                {{ data.item.name | str_limit(15) }}
              </b-link>
              <span
                v-else
                v-b-tooltip.hover.v-default
                :title="data.item.name"
                class="inline-block"
              >
                {{ data.item.name | str_limit(15) }}
              </span>
            </b-media>
          </template>

          <!-- Column: User -->
          <template #cell(email)="data">
            <span v-b-tooltip.hover.v-default :title="data.item.email">
              {{ data.item.email | str_limit(15) }}
            </span>
          </template>
          <template #cell(referral_status_label)="data">
            <v-select
              v-if="$can('Zircly Admin') || $can('Recruiter')"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="data.item.referral_status"
              class="w-"
              :clearable="false"
              :options="statusOptions"
              placeholder="Select Status.."
              :reduce="(val) => val.code"
              @input="(val) => updateStatus(val, data.item.hashid)"
            />
            <span v-else>
              {{ data.item.referral_status_label }}
            </span>
          </template>
          <!-- Column: User -->
          <template #cell(referred_by_name)="data">
            <span
              v-b-tooltip.hover.v-default
              :title="data.item.referred_by_name"
            >
              {{ data.item.referred_by_name | str_limit(15) }}
            </span>
          </template>
          <template #cell(mobile_number)="data">
            <span v-b-tooltip.hover.v-default :title="data.item.mobile_number">
              {{ data.item.mobile_number | str_limit(15) }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('referral_edit') && data.item.referral_status === 0"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('referral_show')"
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                class="btn-sm p-0"
                @click.stop.prevent="openWindow(data.item.resume_file_path)"
                v-if="$can('referral_show') && data.item.resume_file_path"
              >
                <feather-icon icon="FileTextIcon" size="13" class="mr-50" />
                <span>View Resume</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="
                  $can('referral_delete') && data.item.referral_status === 0
                "
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedItems.length == 0"
              v-model="currentPage"
              :total-rows="totalReferralList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>

            <b-button
              v-else-if="$can('referral_delete')"
              class="float-right"
              variant="danger"
              :disabled="selectedItems.length == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ selectedItems.length }} Referral</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import referralStoreModule from "../referralStoreModule";
import useReferralList from "./useReferralList";
import ReferralAdd from "./ReferralAdd.vue";
import Vue from "vue";

vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,

    vSelect,
    ReferralAdd,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-referrals";

    var isDeleteMultipleChecked = ref(false);
    var selectedItems = ref([]);
    var itemDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(REFERRAL_APP_STORE_MODULE_NAME, referralStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const isAddNewReferralSidebarActive = ref(false);

    const {
      fetchReferralList,
      tableColumns,
      perPage,
      currentPage,
      totalReferralList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      referralMetaData,
    } = useReferralList();

    return {
      // Sidebar
      isAddNewReferralSidebarActive,
      fetchReferralList,
      tableColumns,
      perPage,
      currentPage,
      totalReferralList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      referralMetaData,
      isDeleteMultipleChecked,
      itemDeleteCount,
      selectedItems,
    };
  },
  data() {
    return {
      referralId: "",
      /* positionOption: [], */
      userData: {
        name: "",
        email: "",
        mobile_number: "",
        position: "",
        resume_file_path: "",
      },
      statusOptions: [
        { label: "Pending", code: 0 },
        { label: "Converted", code: 1 },
        { label: "Rejected", code: 2 },
      ],
      designationOptions: [],
      allSelected: false,
    };
  },
  created() {
    if (!this.$can("referral_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
    this.fetchDesignationsList();
    /* this.$http.get('/list/positions')
      .then(res => {
        this.positionOption = res.data.data
      })
      .catch(error => {
        console.log(error)
      }) */
  },
  methods: {
    updateStatus(referral_status, referral_hash_id) {
      const self = this;
      self.formData = {};
      self.formData.url =
        "candidate/referral-status-update/" + referral_hash_id;
      self.formData.params = {
        referral_status: referral_status,
      };
      this.$store
        .dispatch("app/store", self.formData)
        .then((res) => {
          self.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Referral Updated",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          self.refetchData();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Referral Updating Failed",
              icon: "XCircleIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    addReferNow() {
      this.referralId = "";
      this.userData = {
        name: "",
        email: "",
        mobile_number: "",
        position: "",
        resume_file_path: "",
        get_customfields_data: [],
      };
      this.isAddNewReferralSidebarActive = true;
    },
    fetchDesignationsList() {
      this.isloading = true;
      this.$store
        .dispatch("app/fetchVacanciesList")
        .then((response) => {
          this.designationOptions = response.data.data;
          this.isloading = false;
        })
        .catch((error) => {
          this.isloading = false;
        });
    },
    editRecord(data) {
      const self = this;
      this.$store
        .dispatch("app-referrals/fetchReferral", data.item.hashid)
        .then((res) => {
          self.referralId = data.item.hashid;
          self.userData = res.data.data;
          self.isAddNewReferralSidebarActive = true;
          self.AddReferral = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    viewRecord(data) {
      this.$router.push(`/view/referral/${data.item.hashid}`).catch(() => {});
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedItems.length} Referral, This cannot be undone`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-referrals/removeMultipleReferral",
              this.selectedItems
            )
            .then((res) => {
              this.isDeleteMultipleChecked = false;
              this.selectedItems = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Referral Deletion failed",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.name}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-referrals/removeReferral", id)
        .then((res) => {
          if (res.data.success) {
            self.refetchData();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Referral Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Referral deleted successfully",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Referral Deletion failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
  watch: {
    allSelected(val) {
      this.selectedItems = [];
      if (val) {
        this.referralMetaData.forEach((item) => {
          this.selectedItems.push(item.hashid);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
