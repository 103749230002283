import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function ReferralList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: "select", sortable: false },
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'referred_by_name', sortable: true, label: 'Referred By' },
    { key: 'mobile_number', sortable: true },
    { key: 'referral_status_label', sortable: true,label: 'Status' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalReferralList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const referralMetaData = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReferralList.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchReferralList = (ctx, callback) => {
    store
      .dispatch('app-referrals/fetchReferrals', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const referrals = response.data.data
        const { total } = response.data.meta

        callback(referrals)
        totalReferralList.value = total
        referralMetaData.value = referrals;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching referrals',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchReferralList,
    tableColumns,
    perPage,
    currentPage,
    totalReferralList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    statusFilter,
    referralMetaData
  }
}
